import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_USER_JOURNEYS,
  ANALYTICS_VIEW_FEATURES
} from 'dpl/constants/analytics';
import { sendRudderstackEvent } from 'dpl/util/analytics';
import BuyerAuthModal from 'dpl/buyer_account/components/BuyerAuthModal';
import Icon from 'dpl/common/components/Icon';
import SmartLink from 'dpl/components/SmartLink';
import { PORTAL_MOUNT_EL } from 'dpl/common/tooltip/components/FloatingTooltipWrapper';

import UserFollowSuccessPopover from './UserFollowSuccessPopover';
import {
  FAVORITE_BUTTON_VARIANTS,
  FOLLOWABLE_STATUSES,
  FOLLOWABLE_STATUS_MESSAGE,
  FOLLOWABLE_TYPES
} from '../utils/constants';
import useUserFollowsData from '../hooks/useUserFollowsData';

export default function FavoriteButton({
  followableTypeId,
  iconSize,
  followableType,
  variant,
  analytics,
  targetEl,
  onChange
}) {
  const [showSuccessPopover, setShowSuccessPopover] = useState(false);
  const [popoverStatus, setPopoverStatus] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const { createUserFollow, removeUserFollow, userFollowsData, hasUserFollow } =
    useUserFollowsData(followableTypeId, {
      followable_type: followableType
    });

  const currentUser = useCurrentUser();
  const { isLoggedIn, value: currentUserData } = currentUser;

  function showPopover(status) {
    setPopoverStatus(status);
    setShowSuccessPopover(true);
  }

  useEffect(() => {
    let timeout;
    if (showSuccessPopover) {
      timeout = setTimeout(() => setShowSuccessPopover(false), 3000);
    }
    return () => clearTimeout(timeout);
  }, [showSuccessPopover]);

  async function updateFavorite(buyerProfileID) {
    if (!hasUserFollow) {
      const eventName =
        followableType === FOLLOWABLE_TYPES.DOG
          ? ANALYTICS_EVENTS.PUPPY_ADDED_TO_FAVORITES
          : ANALYTICS_EVENTS.BREEDER_ADDED_TO_FAVORITES;

      sendRudderstackEvent(eventName, {
        buyer_profile_id:
          currentUserData.data?.buyer_profile_id || buyerProfileID,
        ...analytics,
        view_feature: ANALYTICS_VIEW_FEATURES.FAVORITE_HEART_ICON,
        user_journey: ANALYTICS_USER_JOURNEYS.BUYER_BROWSING
      });

      await createUserFollow({
        body: {
          followable_type: followableType,
          followable_id: followableTypeId
        }
      });
      await userFollowsData.refetch();
      showPopover(FOLLOWABLE_STATUS_MESSAGE.ADDED_TO_FAVORITES);
      onChange?.({ status: FOLLOWABLE_STATUSES.ADDED, followableTypeId });
    } else {
      const eventName =
        followableType === FOLLOWABLE_TYPES.DOG
          ? ANALYTICS_EVENTS.PUPPY_REMOVED_FROM_FAVORITES
          : ANALYTICS_EVENTS.BREEDER_REMOVED_FROM_FAVORITES;

      sendRudderstackEvent(eventName, {
        buyer_profile_id: currentUserData.data.buyer_profile_id,
        ...analytics,
        view_feature: ANALYTICS_VIEW_FEATURES.FAVORITE_HEART_ICON,
        breeder_profile_id: analytics.breeder_profile_id,
        breeder_name: analytics.breeder_name,
        user_journey: ANALYTICS_USER_JOURNEYS.BUYER_BROWSING
      });

      await removeUserFollow();
      await userFollowsData.refetch();
      showPopover(FOLLOWABLE_STATUS_MESSAGE.REMOVED_FROM_FAVORITES);
      onChange?.({ status: FOLLOWABLE_STATUSES.REMOVED, followableTypeId });
    }
  }

  async function handleUserLogin() {
    setShowLoginModal(false);
    const { data: loggedInUser } = await currentUser.refetch();
    if (loggedInUser?.data?.data?.buyer) {
      const { data: userFollows } = await userFollowsData.refetch();
      const userAlreadyFollows = userFollows?.data?.data?.some(
        f => f.followable_id === followableTypeId
      );
      if (!userAlreadyFollows) {
        const buyerProfileID = loggedInUser.data.data.buyer_profile_id;
        updateFavorite(buyerProfileID);
      }
    }
  }

  function handleFavoriteClick(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!isLoggedIn) {
      setShowLoginModal(true);
    } else {
      updateFavorite();
    }
  }

  const popoverMessage =
    popoverStatus === FOLLOWABLE_STATUS_MESSAGE.REMOVED_FROM_FAVORITES ? (
      `${popoverStatus} favorites`
    ) : (
      <span>
        {popoverStatus} your{' '}
        <SmartLink to="/account/favorites" className=" underline">
          favorites
        </SmartLink>
      </span>
    );

  const unFavoritedIcon =
    variant === FAVORITE_BUTTON_VARIANTS.TRANSPARENT_ICON_ONLY
      ? 'favorite-heart-transparent'
      : 'favorite-heart-outline';

  return (
    <>
      <UserFollowSuccessPopover
        title={popoverMessage}
        isVisible={showSuccessPopover}
        targetEl={targetEl}
      >
        <button
          type="button"
          className={classnames({
            'bg-near-white ph1 pv1 ba br-100 b--light-gray':
              variant === FAVORITE_BUTTON_VARIANTS.CIRCLE,
            'ba b--light-gray black br3 pv1 ph1 pv2-md ph2-md':
              variant === FAVORITE_BUTTON_VARIANTS.SQUARE,
            'bg-black-50 pv2 ph2 br-100':
              variant === FAVORITE_BUTTON_VARIANTS.TRANSPARENT_CIRCLE
          })}
          onClick={handleFavoriteClick}
        >
          <Icon
            height={iconSize}
            width={iconSize}
            name={hasUserFollow ? 'favorite-heart' : unFavoritedIcon}
          />
        </button>
      </UserFollowSuccessPopover>
      {showLoginModal && (
        <BuyerAuthModal
          onCloseClick={() => setShowLoginModal(false)}
          onSuccessfulAuth={handleUserLogin}
        />
      )}
    </>
  );
}

FavoriteButton.propTypes = {
  analytics: PropTypes.shape({
    breeder_profile_id: PropTypes.number,
    breeder_name: PropTypes.string,
    breed_id: PropTypes.number,
    page_number: PropTypes.number,
    position: PropTypes.number,
    puppies_available: PropTypes.number,
    transportation: PropTypes.arrayOf(PropTypes.string),
    view: PropTypes.string
  }).isRequired,
  followableTypeId: PropTypes.number.isRequired,
  iconSize: PropTypes.string,
  followableType: PropTypes.oneOf(Object.values(FOLLOWABLE_TYPES)).isRequired,
  variant: PropTypes.oneOf(Object.values(FAVORITE_BUTTON_VARIANTS)),
  // eslint-disable-next-line react/forbid-prop-types
  targetEl: PropTypes.any,
  onChange: PropTypes.func
};

FavoriteButton.defaultProps = {
  iconSize: '20px',
  variant: FAVORITE_BUTTON_VARIANTS.CIRCLE,
  targetEl: PORTAL_MOUNT_EL,
  onChange: null
};
