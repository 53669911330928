import React from 'react';
import PropTypes from 'prop-types';

import SmartImage from 'dpl/components/SmartImage';
import Icon from 'dpl/common/components/Icon';
import { isMobileUA } from 'dpl/shared/utils';

const IS_MOBILE_UA = isMobileUA();
export default function MeetTheBreeder({ breederProfile }) {
  const {
    name: programName,
    meet_the_breeder_paragraph: meetTheBreederParagraph,
    meet_the_breeder_photo_url: meetTheBreederPhotoUrl,
    profile_url: breederProfileUrl,
    user,
    testimonials_count: testimonialsCount
  } = breederProfile;

  return (
    <div className="PuppyModalMeetTheBreeder">
      <div className="bb b--light-gray pb6 mb6">
        {Boolean(meetTheBreederPhotoUrl) && (
          <SmartImage className="br3 w-100 mb3" src={meetTheBreederPhotoUrl} />
        )}

        <p className="fw-medium mb4">
          Hi, I&apos;m {user.first_name} from{' '}
          <a
            href={breederProfileUrl}
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {programName}
          </a>
        </p>

        <p>{meetTheBreederParagraph}</p>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex-auto">
          <div className="fw-medium pr2">{programName}</div>
          {testimonialsCount > 0 && (
            <div className="inline-flex items-center mt1 pv1 ph2 br3 f2 bg-yellow-10">
              <Icon
                className="mr1 gold"
                name="star-filled-16"
                width="14px"
                height="14px"
              />
              {testimonialsCount}{' '}
              {testimonialsCount > 1 ? 'verified stories' : 'verified story'}
            </div>
          )}
        </div>
        <div className="nowrap flex">
          <a
            className="lh-normal fw-medium ph3 pv2 br3 ba b--moon-gray bg-light-gray black"
            href={breederProfileUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {IS_MOBILE_UA ? 'View breeder' : 'View breeder profile'}
          </a>
        </div>
      </div>
    </div>
  );
}

MeetTheBreeder.propTypes = {
  breederProfile: PropTypes.shape({
    name: PropTypes.string,
    user: PropTypes.shape({ first_name: PropTypes.string }),
    profile_url: PropTypes.string,
    meet_the_breeder_photo_url: PropTypes.string,
    meet_the_breeder_paragraph: PropTypes.string,
    testimonials_count: PropTypes.number
  }).isRequired
};
