import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isMobileUA } from 'dpl/shared/utils';
import { STATUS } from 'dpl/breeder_preferred_qualifications/utils/constants';
import PreferredBreederBadge from 'dpl/breeder_preferred_qualifications/components/PreferredBreederBadge';
import SmartImage from 'dpl/components/SmartImage';

const IS_MOBILE_UA = isMobileUA();

export default function RaisedByBreederBox({ className, breederProfile }) {
  const {
    name: programName,
    user,
    profile_photo_url: breederProfilePhotoUrl,
    profile_url: breederProfileUrl,
    preferred_qualification: preferredQualification
  } = breederProfile;

  const imageSize = IS_MOBILE_UA ? '44px' : '56px';
  const preferredBreederBadgeSize = IS_MOBILE_UA
    ? { width: '20px', height: '22px' }
    : { width: '28px', height: '32px' };

  const isPreferredBreeder =
    preferredQualification?.status === STATUS.PREFERRED;

  return (
    <div
      className={classnames(
        'RaisedByBreederBox flex items-center justify-between',
        className
      )}
    >
      <div className="flex items-center flex-auto pr2-md">
        <div className="flex relative">
          <SmartImage
            className="br-100"
            width={imageSize}
            height={imageSize}
            src={breederProfilePhotoUrl}
            crop
          />
          {isPreferredBreeder && (
            <PreferredBreederBadge
              className="absolute right-0 bottom-0 nr2"
              width={preferredBreederBadgeSize.width}
              height={preferredBreederBadgeSize.height}
              hideTooltip
            />
          )}
        </div>
        <div className="ml3 font-16-mobile f3-lg flex-auto">
          <p>Raised by</p>
          <p className="fw-medium truncate">
            {user.first_name} from{' '}
            <a
              href={breederProfileUrl}
              className="underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {programName}
            </a>
          </p>
        </div>
      </div>
      <div className="nowrap db-l dn">
        <a
          className="lh-normal fw-medium ph3 pv2 br3 ba b--moon-gray bg-light-gray black"
          href={breederProfileUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          View breeder profile
        </a>
      </div>
    </div>
  );
}

RaisedByBreederBox.propTypes = {
  breederProfile: PropTypes.shape({
    name: PropTypes.string,
    user: PropTypes.shape({ first_name: PropTypes.string }),
    profile_url: PropTypes.string,
    profile_photo_url: PropTypes.string,
    preferred_qualification: PropTypes.shape({
      status: PropTypes.string
    })
  }).isRequired,
  className: PropTypes.string
};

RaisedByBreederBox.defaultProps = {
  className: ''
};
