import React from 'react';
import PropTypes from 'prop-types';

export default function PuppyModalSection({ children, title, subtitle }) {
  return (
    <section className="PuppyModalSection bt b--light-gray pv6">
      <h6 className="f4 lh-title">{title}</h6>
      {subtitle && <p className="font-16-mobile f3-lg mt2">{subtitle}</p>}
      <div className="font-16-mobile f3-lg mt6">{children}</div>
    </section>
  );
}

PuppyModalSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
};

PuppyModalSection.defaultProps = {
  subtitle: null
};
