import React from 'react';
import PropTypes from 'prop-types';

import { isMobileUA } from 'dpl/shared/utils';
import ClampedMultiLineText from 'dpl/common/components/ClampedMultiLineText';
import FavoriteButton from 'dpl/favorites/components/FavoriteButton';
import {
  FOLLOWABLE_TYPES,
  FAVORITE_BUTTON_VARIANTS
} from 'dpl/favorites/utils/constants';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import { ANALYTICS_VIEWS } from 'dpl/constants/analytics';
import HealthTierBadge from 'dpl/common/components/HealthTierBadge';
import { PUPPY } from 'dpl/common/utils/constants';

import PuppyPriceBadge from './PuppyPriceBadge';
import RaisedByBreederBox from './RaisedByBreederBox';

const IS_MOBILE_UA = isMobileUA();
// calculation: 16 (mobile font size) * (24 / 18)
const MOBILE_LINE_HEIGHT = 21.33;

export default function GetToKnowPuppy({
  breederProfile,
  name,
  description,
  price,
  shouldHideBreederBox,
  puppyId,
  healthTierTitle
}) {
  const { isLoggedIn, isBuyer } = useCurrentUser();
  const showFavoriteButton = !isLoggedIn || isBuyer;

  return (
    <div className="PuppyModalGetToKnowPuppy">
      {!IS_MOBILE_UA && Boolean(description) && (
        <h6 className="f4 lh-title mb4">Get to know {name}</h6>
      )}
      {IS_MOBILE_UA && (
        <div className="mb6">
          <div className="flex justify-between items-center">
            <p className="font-16 fw-medium">Get to know</p>
            <div className="flex items-center">
              {price && <PuppyPriceBadge price={price} />}
              {showFavoriteButton && (
                <FavoriteButton
                  analytics={{
                    breeder_name: breederProfile.name,
                    breeder_profile_id: breederProfile.id,
                    view: ANALYTICS_VIEWS.PUPPY_MODAL
                  }}
                  followableTypeId={puppyId}
                  followableType={FOLLOWABLE_TYPES.DOG}
                  variant={FAVORITE_BUTTON_VARIANTS.SQUARE}
                  iconSize="20px"
                />
              )}
            </div>
          </div>
          <div>
            <h6>{name}</h6>
            {healthTierTitle && (
              <div className="mt1">
                <HealthTierBadge
                  healthTierTitle={healthTierTitle}
                  type={PUPPY}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {description && (
        <ClampedMultiLineText
          text={description}
          numOfLines={5}
          fadeToButton
          className="font-16-mobile f3-lg mb4"
          displayMoreText
          moreText="Read more"
          lessText="Show less"
          lineHeight={IS_MOBILE_UA ? MOBILE_LINE_HEIGHT : 24}
        />
      )}
      {!shouldHideBreederBox && (
        <RaisedByBreederBox breederProfile={breederProfile} />
      )}
    </div>
  );
}

GetToKnowPuppy.propTypes = {
  breederProfile: PropTypes.shape({
    name: PropTypes.string,
    user: PropTypes.shape({ first_name: PropTypes.string }),
    profile_url: PropTypes.string,
    profile_photo_url: PropTypes.string,
    id: PropTypes.number
  }).isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  price: PropTypes.string,
  shouldHideBreederBox: PropTypes.bool,
  puppyId: PropTypes.number,
  healthTierTitle: PropTypes.string
};

GetToKnowPuppy.defaultProps = {
  description: null,
  price: null,
  shouldHideBreederBox: false,
  puppyId: null,
  healthTierTitle: null
};
