import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetParams, pushParams } from 'dpl/actions/query_params';
import {
  ANALYTICS,
  DOG_ID,
  INITIAL_INQUIRY_SOURCE,
  LITTER_ID,
  PUPPY_ID
} from 'dpl/constants/query_params';
import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { dogModalsLitterResourceDefintion } from 'dpl/dog_modals/utils/queryDefinitions';
import { nameUnnamedPuppies } from 'dpl/shared/utils/breeder_app';
import {
  collectTrackedExperiments,
  sendRudderstackEvent
} from 'dpl/util/analytics';
import { ANALYTICS_EVENTS, ANALYTICS_VIEWS } from 'dpl/constants/analytics';
import { INITIAL_INQUIRY_SOURCE_VIEWS } from 'dpl/initial_inquiry/utils/analytics';

export default function usePuppyModal() {
  const dispatch = useDispatch();

  const { analytics, currentPuppyId, currentLitterId, initialInquirySource } =
    useSelector(({ queryParams }) => ({
      analytics: queryParams[ANALYTICS] || {},
      currentPuppyId: Number(queryParams[PUPPY_ID]),
      currentLitterId: Number(queryParams[LITTER_ID]),
      initialInquirySource: queryParams[INITIAL_INQUIRY_SOURCE]
    }));

  const isPuppyModalOpen =
    Boolean(currentPuppyId && currentLitterId) &&
    // If the request is coming from the puppy search results or favorite puppies
    // Initial Inquiry experience, keep the Puppy modal closed.
    !INITIAL_INQUIRY_SOURCE_VIEWS.includes(initialInquirySource);

  const litterData = useResourceQuery({
    ...dogModalsLitterResourceDefintion(currentLitterId),
    enabled: Boolean(currentLitterId && currentPuppyId)
  });

  const litter = litterData.value.data;

  const puppies = nameUnnamedPuppies(litter.puppies);
  const puppyIdx = puppies.findIndex(({ id }) => id === currentPuppyId);
  const puppy = puppies[puppyIdx] || {};

  const puppyMediaFiles = [...(puppy.media_files || [])];
  if (puppy.profile_photo_url) {
    puppyMediaFiles.unshift({
      id: puppy.profile_photo_id,
      url: puppy.profile_photo_url
    });
  }

  useEffect(() => {
    if (!litterData.isSuccess || !isPuppyModalOpen) {
      return;
    }

    const props = {
      ...analytics,
      breeder_profile_id: litter.breeder_profile.id,
      puppy_id: currentPuppyId,
      is_available: puppy.available,
      photos: puppyMediaFiles.length,
      view: ANALYTICS_VIEWS.PUPPY_MODAL,
      view_feature: null,
      view_feature_location: null,
      experiments: collectTrackedExperiments()
    };
    sendRudderstackEvent(ANALYTICS_EVENTS.PUPPY_MODAL_VIEWED, props);
  }, [currentPuppyId, litterData.isSuccess, isPuppyModalOpen]);

  function resetPuppyModalParams() {
    dispatch(resetParams(PUPPY_ID, LITTER_ID, ANALYTICS, { hash: true }));
  }

  function handlePuppyClick(puppyId, litterId) {
    resetPuppyModalParams();
    dispatch(
      pushParams({ [LITTER_ID]: litterId, [PUPPY_ID]: puppyId }, { hash: true })
    );
  }

  function handleParentClick(dogId) {
    resetPuppyModalParams();
    dispatch(pushParams({ [DOG_ID]: dogId }, { hash: true }));
  }

  return {
    breederProfile: litter.breeder_profile,
    litter,
    puppy,
    puppyMediaFiles,
    puppies,
    previousPuppy: puppies[puppyIdx - 1],
    nextPuppy: puppies[puppyIdx + 1],
    isSuccess: litterData?.isSuccess,
    isPuppyModalOpen,
    onPuppyClick: handlePuppyClick,
    onParentClick: handleParentClick,
    onModalClose: resetPuppyModalParams
  };
}
