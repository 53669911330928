import React from 'react';
import PropTypes from 'prop-types';

import VaccinationBanner from './VaccinationBanner';

export default function AboutPuppy({ puppy }) {
  const details = [
    ['Breed', puppy.breed_name],
    ['Gender', puppy.gender],
    ['Age', puppy.age],
    ['Ready on', puppy.formatted_available_date],
    ['Generation', puppy.generation],
    ['Color', puppy.color],
    ['Size', puppy.size],
    ['Coat type', puppy.coat]
  ];

  return (
    <div className="PuppyModalAboutPuppy">
      <ul className="mb2">
        {details.map(
          ([label, value]) =>
            Boolean(value) && (
              <li key={label} className="row pb4">
                <div className="col-4">
                  <span className="fw-medium">{label}</span>
                </div>
                <div className="col-8">
                  <p className="ttc">{value}</p>
                </div>
              </li>
            )
        )}
      </ul>
      <VaccinationBanner puppyName={puppy.name} />
    </div>
  );
}

AboutPuppy.propTypes = {
  puppy: PropTypes.shape({
    breed_name: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    age: PropTypes.string.isRequired,
    formatted_available_date: PropTypes.string.isRequired,
    generation: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    coat: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};
