import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { PuppyPropTypes } from 'dpl/shared/utils/customPropTypes';
import SmartImage from 'dpl/components/SmartImage';
import DogPlaceholderSvg from 'dpl/common/components/DogPlaceholderSvg';

function PuppySibling({ name, photographUrl, onClick, reserved }) {
  return (
    <div className="PuppySibling nt2 mb4">
      <button
        className="hover-bg-near-white br3 w-100 nh2"
        type="button"
        onClick={onClick}
      >
        <div className="mv2 mh2 flex items-center">
          {photographUrl ? (
            <SmartImage
              Tag="div"
              className="bg-center cover br-100 flex-none"
              height="48px"
              width="48px"
              crop
              src={photographUrl}
            />
          ) : (
            <DogPlaceholderSvg
              className="w-100 h-100 bg-near-white br-100 flex-none"
              height="48px"
              width="48px"
              puppy
            />
          )}
          <div className="ml3 flex-auto">
            <p className="fw-medium truncate">{name}</p>
            <p className="flex items-center">
              <span
                className={classnames('ph1 pv1 br-100 mr1', {
                  'bg-red': reserved,
                  'bg-green': !reserved
                })}
              />
              {reserved ? 'Reserved' : 'Available'}
            </p>
          </div>
        </div>
      </button>
    </div>
  );
}

PuppySibling.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  photographUrl: PropTypes.string,
  reserved: PropTypes.bool.isRequired
};

PuppySibling.defaultProps = {
  photographUrl: null
};

export default function PuppySiblings({
  litterId,
  puppies,
  currentPuppy,
  onPuppyClick
}) {
  return (
    <div className="PuppyModalPuppySiblings">
      <div className="row">
        {puppies.map(puppy => {
          if (puppy.id === currentPuppy.id) {
            return null;
          }

          return (
            <div className="col-6" key={puppy.id}>
              <PuppySibling
                name={puppy.name}
                onClick={() => onPuppyClick(puppy.id, litterId)}
                photographUrl={puppy.profile_photo_url}
                reserved={puppy.reserved}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

PuppySiblings.propTypes = {
  litterId: PropTypes.number.isRequired,
  currentPuppy: PropTypes.shape({
    id: PropTypes.number
  }),
  onPuppyClick: PropTypes.func.isRequired,
  puppies: PropTypes.arrayOf(PropTypes.shape(PuppyPropTypes)).isRequired
};

PuppySiblings.defaultProps = {
  currentPuppy: {}
};
