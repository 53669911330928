import React from 'react';
import PropTypes from 'prop-types';

import {
  DESCRIPTIVE_BADGE_VARIANTS,
  DESCRIPTIVE_BADGE_SIZES
} from 'dpl/common/utils/constants';
import DescriptiveBadge from 'dpl/common/components/DescriptiveBadge';

export default function PuppyPriceBadge({ price }) {
  return (
    <DescriptiveBadge
      variant={DESCRIPTIVE_BADGE_VARIANTS.REGULAR}
      size={DESCRIPTIVE_BADGE_SIZES.LARGE}
      label={price}
      borderRadiusClassName="br3"
      className="mr2"
    />
  );
}

PuppyPriceBadge.propTypes = {
  price: PropTypes.string.isRequired
};
