import PropTypes from 'prop-types';
import React from 'react';

import { ANALYTICS_VIEWS } from 'dpl/constants/analytics';

import ModalRequestMoreInfoButton from '../../components/ModalRequestMoreInfoButton';

export default function PuppyModalCtaButton({
  breederProfile,
  litterId,
  puppy
}) {
  const analytics = {
    breeder_preferred_status: breederProfile?.preferred_qualification?.status,
    view: ANALYTICS_VIEWS.PUPPY_MODAL,
    litter_id: litterId,
    puppy_id: puppy.id
  };

  if (puppy.reserved || puppy.snoozed || puppy.litter_archived) {
    return (
      <button
        className="fw-medium lh-normal tc br3 bg-red white w-100 pv3"
        disabled
        type="button"
      >
        Reserved
      </button>
    );
  }

  return (
    <ModalRequestMoreInfoButton
      analytics={analytics}
      breederProfileId={breederProfile.id}
    />
  );
}

PuppyModalCtaButton.propTypes = {
  breederProfile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    profile_url: PropTypes.string.isRequired,
    preferred_qualification: PropTypes.shape({
      status: PropTypes.string
    })
  }).isRequired,
  litterId: PropTypes.number.isRequired,
  puppy: PropTypes.shape({
    id: PropTypes.number.isRequired,
    reserved: PropTypes.bool.isRequired,
    snoozed: PropTypes.bool.isRequired,
    litter_archived: PropTypes.bool.isRequired
  }).isRequired
};
