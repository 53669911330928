import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'dpl/common/components/Icon';
import { DELIVERY_PATH } from 'dpl/shared/constants/urls';

function TransportItem({ iconName, text }) {
  return (
    <div className="TransportItem mb3 col-md-6 col-12 flex">
      <div className="self-start br-100 ba b--light-gray pv2 ph2">
        <Icon
          className="black-50"
          name={`transportation-${iconName}`}
          width="16px"
          height="16px"
        />
      </div>
      <p className="flex-auto pl2 pr2 pr4-md pt1">{text}</p>
    </div>
  );
}

TransportItem.propTypes = {
  iconName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default function TransportationOptions({
  deliveryMethods,
  puppyName,
  breederName
}) {
  const details = {
    pickup: {
      icon: 'pickup',
      text: `Pick up ${puppyName} at ${breederName}'s home`
    },
    'meet-halfway': {
      icon: 'car',
      text: `Meet ${breederName} halfway to pickup ${puppyName}`
    },
    'flight-nanny': {
      icon: 'plane',
      text: `Fly ${puppyName} home with a flight nanny`
    },
    'air-cargo': {
      icon: 'plane',
      text: `Fly ${puppyName} home via pet-safe air cargo`
    },
    'airport-pickup': {
      icon: 'plane',
      text: `Meet at an airport near ${breederName}`
    },
    'ground-transport': {
      icon: 'car',
      text: `Get ${puppyName} driven to you by a transporter`
    }
  };

  return (
    <div className="PuppyModalTransportationOptions ">
      <div className="row no-gutters pb3">
        {deliveryMethods?.map(dm => {
          return (
            <TransportItem
              key={dm}
              iconName={details[dm].icon}
              text={details[dm].text}
            />
          );
        })}
      </div>
      <a
        href={DELIVERY_PATH}
        className="inline-flex items-center link--primary fw-medium"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more about your options{' '}
        <span className="f5 ph2 nv2">&#x203A;</span>
      </a>
    </div>
  );
}

TransportationOptions.propTypes = {
  deliveryMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  puppyName: PropTypes.string.isRequired,
  breederName: PropTypes.string.isRequired
};
