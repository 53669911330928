import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'dpl/common/components/Icon';

export default function VaccinationBanner({ puppyName }) {
  return (
    <div className="PuppyModalVaccinationBanner">
      <div className="flex items-center pv4 ph4 br3 bg-lightest-purple">
        <div className="mr4">
          <Icon
            name="health-badge-heart"
            width="24px"
            height="24px"
            className="purple"
          />
        </div>
        <p className="f2 f3-lg">
          {puppyName} will receive vaccinations and a vet exam before going
          home.
        </p>
      </div>
    </div>
  );
}

VaccinationBanner.propTypes = {
  puppyName: PropTypes.string.isRequired
};
