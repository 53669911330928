import React from 'react';
import PropTypes from 'prop-types';

import { DogPropTypes } from 'dpl/shared/utils/customPropTypes';
import { getOrderedParents } from 'dpl/shared/utils/breeder_app';
import { GENDERS } from 'dpl/shared/constants/breeder_app';
import SmartImage from 'dpl/components/SmartImage';
import HealthTierBadge from 'dpl/common/components/HealthTierBadge';
import DogPlaceholderSvg from 'dpl/common/components/DogPlaceholderSvg';

function TbdDogParent({ gender }) {
  return (
    <div className="PuppyModalPuppyParent">
      <div className="aspect-ratio aspect-ratio--6x4">
        <div className="aspect-ratio--object">
          <DogPlaceholderSvg className="h-100 w-100" />
        </div>
      </div>
      <p className="mt3">
        <span className="fw-medium">TBD</span> |{' '}
        {gender === GENDERS.FEMALE ? 'Mom' : 'Dad'}
      </p>
    </div>
  );
}

TbdDogParent.propTypes = {
  gender: PropTypes.oneOf(Object.values(GENDERS)).isRequired
};

function PuppyParent({
  gender,
  photographUrl,
  name,
  healthTierTitle,
  onClick
}) {
  return (
    <div className="PuppyModalPuppyParent">
      <button className="w-100" type="button" onClick={onClick}>
        <div className="box-shadow-hover hide-child aspect-ratio aspect-ratio--6x4">
          <div className="aspect-ratio--object">
            {photographUrl ? (
              <SmartImage
                Tag="div"
                className="cover bg-center br3 h-100 w-100"
                src={photographUrl}
              />
            ) : (
              <DogPlaceholderSvg className="h-100 w-100" />
            )}
            <span className="f2 f3-lg bg-black-60 absolute absolute--fill child flex items-center justify-center white br3">
              View
            </span>
          </div>
        </div>
        <p className="mt3">
          <span className="fw-medium">{name}</span> |{' '}
          {gender === GENDERS.FEMALE ? 'Mom' : 'Dad'}
        </p>
        {healthTierTitle && (
          <div className="mt2">
            <HealthTierBadge healthTierTitle={healthTierTitle} />
          </div>
        )}
      </button>
    </div>
  );
}

PuppyParent.propTypes = {
  gender: PropTypes.oneOf(Object.values(GENDERS)).isRequired,
  photographUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  healthTierTitle: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

PuppyParent.defaultProps = {
  photographUrl: null,
  healthTierTitle: null
};

export default function MeetPuppyParents({ parents, onParentClick }) {
  return (
    <div className="PuppyModalMeetPuppyParents">
      <div className="row">
        {getOrderedParents(parents).map(parent => (
          <div key={parent.id} className="col">
            {parent.type === 'TbdDog' ? (
              <TbdDogParent gender={parent.gender} />
            ) : (
              <PuppyParent
                gender={parent.gender}
                name={parent.name}
                photographUrl={parent.profile_photo_url}
                healthTierTitle={parent.health_tier_title}
                onClick={() => onParentClick(parent.id)}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

MeetPuppyParents.propTypes = {
  parents: PropTypes.arrayOf(PropTypes.shape(DogPropTypes)).isRequired,
  onParentClick: PropTypes.func.isRequired
};
