import classNames from 'classnames';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import DogPlaceholderSvg from 'dpl/common/components/DogPlaceholderSvg';
import GoodDogStandards from 'dpl/common/components/GoodDogStandards';
import HealthTierBadge from 'dpl/common/components/HealthTierBadge';
import Icon from 'dpl/common/components/Icon';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import MiniOverlayModal from 'dpl/common/mini_overlay_modal/components/MiniOverlayModal';
import { PUPPY } from 'dpl/common/utils/constants';
import { OPT_OUT_CLASS } from 'dpl/components/FixedScrollWrapper';
import LoadingWrapper from 'dpl/components/LoadingWrapper';
import MediaCarousel from 'dpl/components/MediaCarousel';
import OverlayNavigationArrow from 'dpl/components/OverlayNavigationArrow';
import { ANALYTICS_VIEWS } from 'dpl/constants/analytics';
import FavoriteButton from 'dpl/favorites/components/FavoriteButton';
import {
  FAVORITE_BUTTON_VARIANTS,
  FOLLOWABLE_TYPES
} from 'dpl/favorites/utils/constants';
import { BUYER_APP_STORE_URL } from 'dpl/shared/constants/urls';
import { isMobileUA, isNativeAppUA, scrollTo } from 'dpl/shared/utils';
import { get } from 'dpl/shared/utils/object';

import usePuppyModal from '../hooks/usePuppyModal';
import AboutPuppy from './AboutPuppy';
import GetToKnowPuppy from './GetToKnowPuppy';
import MeetPuppyParents from './MeetPuppyParents';
import MeetTheBreeder from './MeetTheBreeder';
import PuppyModalCtaButton from './PuppyModalCtaButton';
import PuppyModalSection from './PuppyModalSection';
import PuppyPriceBadge from './PuppyPriceBadge';
import PuppySiblings from './PuppySiblings';
import TransportationOptions from './TransportationOptions';

const IS_MOBILE_UA = isMobileUA();
const IS_NATIVE_UA = isNativeAppUA();

export const dimensions = isMobileUA()
  ? {
      width: `${document.body.getBoundingClientRect().width}px`,
      height: '267px'
    }
  : { width: '675', height: '450px' };

export default function PuppyModal() {
  const {
    breederProfile,
    litter,
    puppy,
    puppyMediaFiles,
    nextPuppy,
    previousPuppy,
    puppies,
    isSuccess,
    isPuppyModalOpen,
    onModalClose,
    onPuppyClick,
    onParentClick
  } = usePuppyModal();
  const locationTitle = useSelector(({ server }) => server.locationTitle);
  const { pageName } = useSelector(({ server }) => server);
  const showNavigationArrows = pageName === 'breeder_profiles_show';

  const { description, name, id: puppyId } = puppy;
  const contentsRef = useRef(null);
  const healthTierTitle = puppy?.health_tier?.title;

  useEffect(() => {
    if (contentsRef.current) {
      scrollTo({
        element: contentsRef.current,
        behavior: 'smooth',
        top: 0
      });
    }
  }, [puppy]);

  const { price: puppyPrice } = puppy;

  const currentUser = useCurrentUser();
  const { isLoggedIn, isBuyer } = currentUser;
  const showFavoriteButton = !isLoggedIn || isBuyer;

  const appliedTo = get(
    currentUser,
    'value.data.breeder_profiles_applied_to',
    []
  );

  const baseImageClasses = 'bg-center mw-100 min-w-100';

  const mediaFilesWithAlt = useMemo(() => {
    const mediaFiles = puppyMediaFiles.map(photo => ({
      ...photo,
      alt: locationTitle ? `${name} in ${locationTitle}` : name
    }));

    return mediaFiles;
  }, [BUYER_APP_STORE_URL, IS_MOBILE_UA, locationTitle, name, puppyMediaFiles]);

  const canApply =
    typeof isLoggedIn === 'boolean' && !appliedTo.includes(breederProfile?.id);

  if (!isPuppyModalOpen) {
    return null;
  }

  return (
    <MiniOverlayModal
      className="PuppyModal"
      contentClassName={`bg-white relative ${OPT_OUT_CLASS}`}
      scrollableContent
      hideCloseButton
      onCloseClick={onModalClose}
      maxWidth="700px"
    >
      <LoadingWrapper isLoading={!isSuccess} className="fixed absolute--fill">
        {() => (
          <>
            {showNavigationArrows && previousPuppy && (
              <OverlayNavigationArrow
                direction="left"
                onClick={() => {
                  onPuppyClick(previousPuppy.id, litter.id);
                }}
              />
            )}
            {showNavigationArrows && nextPuppy && (
              <OverlayNavigationArrow
                direction="right"
                onClick={() => {
                  onPuppyClick(nextPuppy.id, litter.id);
                }}
              />
            )}
            <div className="PuppyModal__content relative flex h-100">
              {IS_MOBILE_UA && !IS_NATIVE_UA && (
                <div className="bg-white w-100 absolute top-0 z-max">
                  <button
                    type="button"
                    className="pl5 pt6 pb3"
                    onClick={onModalClose}
                  >
                    <Icon className="black-50" name="close" />
                  </button>
                </div>
              )}
              <div
                className={classNames('overflow-y-auto no-scrollbars', {
                  pt15: IS_MOBILE_UA,
                  br4: !IS_MOBILE_UA,
                  pb7: canApply
                })}
                ref={contentsRef}
              >
                {/* Embedded form impression tracking */}
                {!IS_MOBILE_UA && (
                  <div className="PuppyModal__header pv4 pl10 pr4 flex justify-between items-center">
                    <div className="overflow-hidden">
                      <h5 className="truncate">{name}</h5>
                      {healthTierTitle && (
                        <div className="mt1">
                          <HealthTierBadge
                            healthTierTitle={healthTierTitle}
                            type={PUPPY}
                          />
                        </div>
                      )}
                    </div>

                    <div className="flex">
                      {puppyPrice && <PuppyPriceBadge price={puppyPrice} />}
                      {showFavoriteButton && (
                        <FavoriteButton
                          analytics={{
                            breeder_name: breederProfile.name,
                            breeder_profile_id: breederProfile.id,
                            view: ANALYTICS_VIEWS.PUPPY_MODAL
                          }}
                          followableTypeId={puppyId}
                          followableType={FOLLOWABLE_TYPES.DOG}
                          variant={FAVORITE_BUTTON_VARIANTS.SQUARE}
                          iconSize="24px"
                        />
                      )}
                      {!IS_MOBILE_UA && !IS_NATIVE_UA && (
                        <button
                          type="button"
                          className="ml4"
                          onClick={onModalClose}
                        >
                          <Icon className="black-50" name="close" />
                        </button>
                      )}
                    </div>
                  </div>
                )}

                <div className="bg-black relative">
                  {mediaFilesWithAlt.length > 0 ? (
                    <MediaCarousel
                      className="overflow-x-hidden"
                      files={mediaFilesWithAlt}
                      showArrowsOnHover
                      dimensions={dimensions}
                      smartImageProps={{
                        Tag: 'div',
                        className: classNames(baseImageClasses, 'contain'),
                        loadingClass: 'bg-black'
                      }}
                    />
                  ) : (
                    <DogPlaceholderSvg
                      puppy
                      className="bg-white bb bw1 b--near-white"
                      {...dimensions}
                    />
                  )}
                </div>
                <div className="pv6 pv8-sm ph6 ph10-sm">
                  <div className="mb6">
                    <GetToKnowPuppy
                      breederProfile={breederProfile}
                      name={name}
                      description={description}
                      price={puppyPrice}
                      puppyId={puppyId}
                      healthTierTitle={healthTierTitle}
                    />
                  </div>

                  <PuppyModalSection title={`About ${name}`}>
                    <AboutPuppy puppy={puppy} />
                  </PuppyModalSection>

                  <PuppyModalSection title={`Meet ${name}'s parents`}>
                    <MeetPuppyParents
                      parents={litter.parents}
                      onParentClick={onParentClick}
                    />
                  </PuppyModalSection>

                  <PuppyModalSection
                    title={`Bringing ${name} home`}
                    subtitle={`${name} comes from ${breederProfile.city_state}`}
                  >
                    <TransportationOptions
                      deliveryMethods={breederProfile.ordered_delivery_methods}
                      puppyName={name}
                      breederName={breederProfile.user.first_name}
                    />
                  </PuppyModalSection>

                  <PuppyModalSection title={`Meet ${name}'s breeder`}>
                    <div className="mb6">
                      <MeetTheBreeder breederProfile={breederProfile} />
                    </div>

                    <GoodDogStandards
                      breederName={breederProfile.user.first_name}
                      programName={breederProfile.name}
                    />
                  </PuppyModalSection>

                  {puppies.length > 1 && (
                    <PuppyModalSection title={`${name}'s siblings`}>
                      <PuppySiblings
                        litterId={litter.id}
                        puppies={puppies}
                        currentPuppy={puppy}
                        onPuppyClick={onPuppyClick}
                      />
                    </PuppyModalSection>
                  )}
                </div>
              </div>
              <div className="PuppyModalApplyButton bottom-0 absolute z-max bg-white-80 w-100 ph6 ph10-sm br4 br--bottom">
                <div className="flex mt3 mb6">
                  <PuppyModalCtaButton
                    breederProfile={breederProfile}
                    litterId={litter.id}
                    puppy={puppy}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </LoadingWrapper>
    </MiniOverlayModal>
  );
}
